import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { StateProvider, useStateValue } from "../State"
import initialState from "../State/initialState"
import reducer from "../State/reducer"
import { Link, useStaticQuery, graphql } from "gatsby"
import ReactGA from "react-ga"
import "./layout.sass"
import MenuIcon from "./menu.inline.svg"
import SunIcon from "./sun.inline.svg"
import MoonIcon from "./moon.inline.svg"
import NsfwIcon from "./nsfw.inline.svg"
import SfwIcon from "./sfw.inline.svg"
import OptionsIcon from "./options.inline.svg"
import DeviantRobotLogo from "./deviantrobot.inline.svg"
import Headroom from "react-headroom"
import YoutubeIcon from "./youtube-icon.inline.svg"
import DiscrodIcon from "./discord.inline.svg"
import TwitterIcon from "./twitter-icon.inline.svg"
import TwitchIcon from "./twitch-icon.inline.svg"
import MeetupIcon from "./meetup-icon.inline.svg"
import SpotifyIcon from "./spotify.inline.svg"
import AngleIcon from "./angle.inline.svg"

const Layout = (props) => {

	const { children } = props
	const [state, dispatch] = useStateValue()
	const [googleAnalyticsHasInitialised, setGoogleAnalyticsHasInitialised] = useState(false)
	const [googleAnalyticsCurrentLocation, setGoogleAnalyticsCurrentLocation] = useState()
	const [activePrimaryNavigationMenu, setActivePrimaryNavigationMenu] = useState("main")

	const { site } = useStaticQuery(
		graphql`query {
			site {
				buildTime(formatString: "YYYY-MM-DD-HH-MM-SS")
				siteMetadata {
					googleAnalyticsTrackingID
				}
			}
		}`,
	)

	useEffect(

		() => {

			if (typeof window !== `undefined`) {

				const conditionalBodyClass = (condition, className, entity = document.body) => {

					if (condition) {

						return entity.classList.add(className) 

					} 

					return entity.classList.remove(className)

				}

				const conditionalHTMLClass = (condition, className) => conditionalBodyClass(condition, className, document.documentElement)

				conditionalHTMLClass((state.options.open || state.menu.open), "scrollLock")
				conditionalBodyClass(state.cookies.consent, "stateCookieConsent")
				conditionalBodyClass(state.menu.open, "stateMenuOpen")
				conditionalBodyClass(!state.theme.dark, "stateUiThemeLight")
				conditionalBodyClass(state.options.open, "stateOptionsOpen")
				conditionalBodyClass(state.podcast.selected, "statePodcastSelected")

				// SECTION: Google Analytics
				// NOTE: Google Analytics with Opt-In user consent based on a local
				// storage state preference. We need to check for window so that
				// we don't cause errors with the Gatsby static build.

				if (state.cookies.consent) {

					const trackingURL = window.location.pathname + window.location.search + window.location.hash

					// If we have consent and have not yet initialised the google
					// analytics we should do so.

					if (!googleAnalyticsHasInitialised) {

						// Google Analytics Tracking ID is stored in gatsby-config.js

						ReactGA.initialize(site.siteMetadata.googleAnalyticsTrackingID)

						// Setting the 'anonymizeIp' property to anonymize the IP address of
						// the hit sent to Google Analytics.

						ReactGA.set({ anonymizeIp: true })

						// Recording Google Analytics has been set for this session in
						// component state.

						// This is not stored in Local Storage or Global State as it is
						// session specific. The layout component wraps the application
						// routes so component state will persists for the session.

						setGoogleAnalyticsHasInitialised(true)

					}

					// If the current location hasn't been tracked, we should
					// track the page view.

					if (googleAnalyticsCurrentLocation !== trackingURL) {

						ReactGA.pageview(trackingURL)
						setGoogleAnalyticsCurrentLocation(trackingURL)

					}

				}

				// Local Storage of state
				// Provided that we have consent listen for changes in the state,
				// when changed it update the localStorage value of state

				if (state.cookies.consent) {

					window.localStorage.setItem("applicationState", JSON.stringify(state))

				} else {

					window.localStorage.removeItem("applicationState")

				}

			}

		},
		[state, googleAnalyticsCurrentLocation, googleAnalyticsHasInitialised, site]

	)

	const toggleMenu = () => {

		if (state.menu.open) {

			dispatch({ type: "DISABLE_MENU_OPEN" })

		} else {

			dispatch({ type: "ENABLE_MENU_OPEN" })

		}

	}

	const toggleOptions = () => {

		if (state.options.open) {

			dispatch({ type: "DISABLE_OPTIONS_OPEN" })

		} else {

			dispatch({ type: "ENABLE_OPTIONS_OPEN" })

		}

	}

	const toggleShowNSFW = () => {

		if (state.nsfw.show) {

			dispatch({ type: "DISABLE_NSFW" })

		} else {

			dispatch({ type: "ENABLE_NSFW" })

		}

	}

	const toggleDarkTheme = () => {

		if (state.theme.dark) {

			dispatch({ type: "DISABLE_DARK_THEME" })

		} else {

			dispatch({ type: "ENABLE_DARK_THEME" })

		}

	}

	const cookieConsentToggle = () => {

		if (state.cookies.consent) {

			dispatch({ type: "DISABLE_COOKIE_consent" })

		} else {

			dispatch({ type: "ENABLE_COOKIE_consent" })

		}

	}

	return (

		<>

			<a className="skip-to-content visuallyhidden focusable" href="#content">Skip to content</a>

			<div id="top" className="layout">

				<Headroom disableInlineStyles>

					<header className="site">

						<MenuIcon onClick={toggleMenu} className="menu-icon" />

						<Link className="site-title" onClick={() => {

							setActivePrimaryNavigationMenu("main") 

						}} to="/">Deviant Robot</Link>

						<nav className="primary" role="navigation" aria-labelledby="primary-navigation">

							<div id="primary-navigation" className="visuallyhidden">Primary navigation</div>

							<div className="scroll-container">

								<div className="flex-container">

									<nav className={activePrimaryNavigationMenu === "main" ? "top active-menu" : "top-menu"}>

										<Link onKeyDown={toggleMenu} onClick={toggleMenu} partiallyActive={true} activeClassName="current" to="/about">About</Link>
										<Link onKeyDown={toggleMenu} onClick={toggleMenu} partiallyActive={true} activeClassName="current" to="/articles">Articles</Link>
													
										<span className="sub-menu-link hide-narrow" onClick={() => {

											setActivePrimaryNavigationMenu("articles") 

										}}>Recommended<AngleIcon /></span>
										
										<Link onKeyDown={toggleMenu} onClick={toggleMenu} partiallyActive={true} activeClassName="current" to="/blog">Blog</Link>

									</nav>

									<nav className={activePrimaryNavigationMenu === "articles" ? "sub-menu active-menu" : "sub-menu"}>

										<h3 className="hide-narrow" onClick={() => {

											setActivePrimaryNavigationMenu("main") 

										}}><AngleIcon /> Recommended</h3>

										<Link onClick={toggleMenu} partiallyActive={true} activeClassName="current" to="/recommended/books"><span className="hide-wide">Recommended </span>Books</Link>
										<Link onClick={toggleMenu} partiallyActive={true} activeClassName="current" to="/recommended/podcasts"><span className="hide-wide">Recommended </span>Podcasts</Link>
										<Link onClick={toggleMenu} partiallyActive={true} activeClassName="current" to="/recommended/youtube"><span className="hide-wide">Recommended </span>YouTube</Link>
										
									</nav>

								</div>

							</div>

						</nav>

						<div className="preference-toggle">

							<div className="social hide-wide">
								<ReactGA.OutboundLink target="_blank" eventLabel="YouTube" to="https://www.youtube.com/c/deviantrobot"><YoutubeIcon alt="Deviant Robot YouTube channel" /><span className="hide-mid-narrow">YouTube</span></ReactGA.OutboundLink>
								<ReactGA.OutboundLink target="_blank" eventLabel="Twitch" to="https://www.twitch.tv/deviantrobot"><TwitchIcon alt="Deviant Robot Twitch channel" /><span className="hide-mid-narrow">Twitch</span></ReactGA.OutboundLink>
								<ReactGA.OutboundLink target="_blank" eventLabel="Discord" to="https://discord.gg/7t8xNEBk3A"><DiscrodIcon alt="Deviant Robot Discord" /><span className="hide-mid-narrow">Discord</span></ReactGA.OutboundLink>
							</div>

							<div className="options">

								<OptionsIcon onClick={toggleOptions} />

							</div>

						</div>

						<div className="preference-menu">

							<div className="theme toggle">

								<div role="button" tabIndex="-1" onKeyDown={toggleDarkTheme} onClick={toggleDarkTheme} className={state.theme.dark ? "active option" : "option"}>

									<MoonIcon />

									<span>Switch to Light theme</span>

								</div>

								<div role="button" tabIndex="-1" onKeyDown={toggleDarkTheme} onClick={toggleDarkTheme} className={!state.theme.dark ? "active option" : "option"}>

									<SunIcon />

									<span>Switch to Dark theme</span>

								</div>

							</div>

							<div className="nsfw toggle">

								<div role="button" tabIndex="-1" onKeyDown={toggleShowNSFW} onClick={toggleShowNSFW} className={!state.nsfw.show ? "active option" : "option"}>

									<SfwIcon />
									<span>Show NSFW (Not Safe For Work) Content</span>

								</div>

								<div role="button" tabIndex="-1" onKeyDown={toggleShowNSFW} onClick={toggleShowNSFW} className={state.nsfw.show ? "active option" : "option"} >

									<NsfwIcon />
									<span>Hide NSFW (Not Safe For Work) Content</span>

								</div>

							</div>

						</div>

						{/* END: Options navigation */}

					</header>

				</Headroom>

				<div className="cookies-consent">

					<p>

						Can we use Cookies and Local Storage?
						<sub>We would like to use cookies and Local Storage to improve your user experiences; however by default we will not do so without your consent.</sub>

					</p>

					<p className="action">

						<Link className="button minor" to="/cookie-policy">Find out more</Link>
						<span aria-hidden="true" aria-label="background" role="button" tabIndex="-1" className="button primary" onKeyDown={cookieConsentToggle} onClick={cookieConsentToggle}>Yes, I agree</span>

					</p>

				</div>

				<div role="button" tabIndex="-1" onKeyDown={toggleOptions} onClick={toggleOptions} className="scroll-lock-shade" />

				<main id="content" role="main" aria-labelledby="main-title" className="site">

					{children}

				</main>

				<footer className="site">

					<div className="page">

						<nav role="navigation" aria-labelledby="secondary-navigation">

							<div id="secondary-navigation" className="visuallyhidden">Secondary navigation</div>

							<a className="back-top-top" href="#top">Back to top</a>

							<Link to="/">Home</Link>
							<Link to="/getdeviant">Get Deviant</Link>
							<Link to="/cookie-policy">Cookie Policy</Link>
							<Link to="/special-thanks">Special thanks</Link>

						</nav>

						<div className="pride">

							<nav aria-labelledby="social-navigation">

								<div id="social-navigation" className="visuallyhidden">Social navigation</div>

								<ReactGA.OutboundLink eventLabel="YouTube" to="https://www.youtube.com/c/deviantrobot"><YoutubeIcon alt="Deviant Robot YouTube channel" /></ReactGA.OutboundLink>
								<ReactGA.OutboundLink eventLabel="Twitter" to="https://www.twitter.com/thedeviantrobot"><TwitterIcon alt="Deviant Robot on Twitch" /></ReactGA.OutboundLink>
								<ReactGA.OutboundLink eventLabel="Twitch" to="https://www.twitch.tv/deviantrobot"><TwitchIcon alt="Deviant Robot Twitch channel" /></ReactGA.OutboundLink>
								<ReactGA.OutboundLink eventLabel="Discord" to="https://discord.gg/7t8xNEBk3A"><DiscrodIcon alt="Deviant Robot Discord" /></ReactGA.OutboundLink>
								<ReactGA.OutboundLink eventLabel="UK Meetup Group" to="https://www.meetup.com/deviant-robot/"><MeetupIcon alt="Deviant Robot Meetup" /></ReactGA.OutboundLink>
								<ReactGA.OutboundLink eventLabel="Spotify" to="https://open.spotify.com/show/5aAHNYVOhRCyWm11VjADGp?si=n1LT_qm5RfuO_k_hPaeDGQ"><SpotifyIcon alt="Deviant Robot Spotify " /></ReactGA.OutboundLink>

							</nav>

							<p>DeviantRobot.com was created and is owned by William Owen (<ReactGA.OutboundLink eventLabel="wo-dev" to="https://wo.dev">WO.dev</ReactGA.OutboundLink>). It has been made possible by a hugely supportive community for like-minded nerds.</p>
							<p>CAUTION: DeviantRobot.com is safe to use while pregnant. Please feel free to use this website while under the influence of alcohol. Avoid using while using any other site. Stop using if irritation develops. May cause drowsiness, DeviantRobot.com was not tested on animals, only family and friends. No HTML was harmed during the creation of this website. The site is 100% fat-free and suitable for Vegans. For external use only. Do not enter the Konami code while using this website</p>

						</div>

					</div>

					<DeviantRobotLogo />

					<p>Deviant Robot. Relentlessly badass since 2014s</p>
					<p className="last-build">Last build: {site.buildTime}</p>
					<p>&copy; {new Date().getFullYear()} William Owen unless otherwise stated.<br /> All rights reserved. Website design and development by William Owen</p>

				</footer>

			</div>

		</>

	)

}

Layout.propTypes = {

	children: PropTypes.node.isRequired,

}

const LayoutWithState = (props) => (

	<StateProvider initialState={initialState} reducer={reducer}>
		<Layout>
			{props.children}
		</Layout>
	</StateProvider>

)

LayoutWithState.propTypes = {

	children: PropTypes.node.isRequired,

}

export default LayoutWithState
