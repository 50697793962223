const initialState = {

	options: {
		open: false,
	},

	page: {
		title: null,
	},

	menu: {
		open: false,
	},

	cookies: {
		consent: false,
	},

	theme: {
		dark: true,
	},

	nsfw: {
		show: false,
	},

	podcast: {

		selected: false,
		playing: false,
		mediaURL: '',
		mediaTitle: '',
		mediaArt: '',
		playPosition: 0

	}

}

export default initialState