/** Check to see if we are running on the client. */
export const isClientSide = ():boolean => (typeof window !== `undefined`)

/** Applies or removes a class to the Body element based on a supplied condition. */
export const conditionalBodyClass = (condition:boolean, className:string):void => {

	if (condition) {

		document.body.classList.add(className)

	} else {

		document.body.classList.remove(className)

	}

}

/** Applies or removes a class to the HTML element based on a supplied condition. */
export const conditionalHTMLClass = (condition:boolean, className:string):void => {

	if (condition) {

		document.documentElement.classList.add(className)

	} else {

		document.documentElement.classList.remove(className)

	}

}

//** Comverts a numbers of seconds to house min an seocnds padded string. */
export const secondsToHms = (d:number):string => {


	d = Number(d)
	d = isNaN(d) ? 0 : d

	const h = Math.floor(d / 3600).toString().padStart(2, "0")
	const m = Math.floor(d % 3600 / 60).toString().padStart(2, "0")
	const s = Math.floor(d % 3600 % 60).toString().padStart(2, "0")

	return `${h}:${m}:${s}`

}

//** Adds or removes a class name to and exisiting set of class names based on a condition */
export const conditionalAddToClassName = (newClassName:string, currentClassName:string, condition:boolean):string => {

	let classNameArray = currentClassName.split(" ")

	if (condition) {

		if (classNameArray.indexOf(newClassName) === -1) {

			classNameArray.push(newClassName)

		}

	}else{

		classNameArray = classNameArray.filter(className=>className!==newClassName)

	}

	return classNameArray.join(' ')

}