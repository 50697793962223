import React, { createContext, useContext, useReducer } from 'react';
import { isClientSide } from '../../modules/rational-gatsby';

export const StateContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => {

	// Get state from storage and if found override
	// the initial state with the local storage values.

	let localStorageState = null

	// Don't attempt to define this variable if there is no
	// window variable.

	if (isClientSide()) {

		localStorageState = JSON.parse(window.localStorage.getItem("applicationState"))

	}

	// const localStorageStatePodcastPlayPosition = (isClientSide) ? JSON.parse(window.localStorage.getItem("podcastPlayPosition")) : null

	// Regardless of the previous state some options should
	// be overridden on first load.

	const sensibleOverrides = {

		options: {
			open: false // Make sure that the options menu is closed
		},
		page: {
			title: null // Don't remember the previously set page title
		},
		nsfw: {
			show: false // Always hide nsfw on a new session
		},

	}

	// const podcastSensibleOverrides = {

	// 	playPosition: localStorageStatePodcastPlayPosition, // We can't autoplay

	// }

	initialState = localStorageState == null ? initialState : {
		...initialState,
		...localStorageState,
		...sensibleOverrides,
	}

	return (

		<StateContext.Provider value={useReducer(reducer, initialState)}>

			{children}

		</StateContext.Provider>

	)

};

export const useStateValue = () => useContext(StateContext);